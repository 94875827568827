.textarea_wrapper {
  label,
  textarea {
    font-size: .8rem;
    letter-spacing: 1px;
    width: 100%;
    min-height: 160px;
  }
  textarea {
    padding: 10px;
    line-height: 1.5;
    background: #FFFFFF;
    border: 1px solid #616882;
    box-sizing: border-box;
    border-radius: 4px;
  }

  label {
    display: block;
    margin-bottom: 10px;
  }
}
