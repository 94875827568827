.copy-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  &__icon {
    display: block;
    width: 18px;
    height: 20px;
    background: url("../../public/assets/icons/copy-icon.svg") center center/cover no-repeat;
    margin: 2px 3px 2px 3px;
  }
}
