.contact-form {
  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 2em;

    .custom-input-component {
      flex: 1;
      margin-right: 1em;
    }
    :last-child {
      margin-right: 0;
    }

    .phoneType {
      margin-right: 1em;
      width: 10em;
    }
  }
}
