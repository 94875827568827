//TODO: Change color in all hover effect

// Base style for button
.custom-button-component {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 238px;
  min-height: 56px;

  padding: 18px 38px;
  margin: 0;
  outline: 0;
  border-radius: 4px;

  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;

  box-sizing: border-box;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
  transition: all 0.3ms ease-in-out;
  &.inline {
    display: inline-flex;
  }

  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.7;
    .loader {
      height: 100%;
      > img {
        height: 25px;
        margin-top: 7px;
      }
    }
  }

  &.full-width {
    width: 100%;
  }

  &.size-small {
    padding-top: 14px;
    padding-bottom: 14px;
    min-width: 190px;
    min-height: 48px;
  }

  &.size-big {
    color: #ffffff;
    padding-top: 21.5px;
    padding-bottom: 21.5px;
    min-height: 65px;
    min-width: max-content;
    justify-content: flex-start;
    &:hover {
      color: #ffffff;
    }
    &:focus {
      color: #ffffff;
    }
  }

  .button-plus-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../../public/assets/icons/plus-icon.svg') center center/cover no-repeat;
    margin-right: 7px;
  }

  .custom-loader-wrap {
    min-height: auto;
    .custom-loader {
      margin: 0;
    }
  }

}
