@import "mixins";
.feedback-wrap {
  input:disabled, textarea:disabled, select:disabled, input:disabled + span.checkbox_checkmark,
  input:disabled + span.radio_button_checkmark {
    background-color: rgba(100, 100, 100, 0.1)
  }
  @include max-width-880 {
    padding: 0 15px;
  }
  &__header {
    font-family: Magallanes, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 48px;
    text-align: center;
    color: #000000;
    margin-top: 50px;
    @include max-width-768 {
      font-size: 25px;
      margin-top: 15px;
    }
    @include max-width-480 {
      font-size: 22px;
      line-height: 22px;
      margin-top: 15px;
    }
  }
  .feedback-form-block {
    max-width: 868px;
    margin: 50px auto;
    @include max-width-768 {
      margin: 15px auto;
    }
    &__title {
      font-family: Magallanes, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      color: #090909;
      @include max-width-480 {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__submit {
      margin-top: 32px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      @include max-width-480 {
        justify-content: center;
      }
      .button_component_wrapper {
        width: 165px;
      }
    }
    &__main-error {
      display: block;
      margin-top: 2px;
      font-size: 20px;
      line-height: 14px;
      color: #FF6F3E;
      font-weight: 600;
    }
  }
}
.not_exist {
  text-align: center;
  font-size: 24px;
  margin-top: 50px;
  color: #8B9898;
}
