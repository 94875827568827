.input_component_wrapper {
  position: relative;
  input {
    width: 100%;
    padding-left: 10px;
    background: #fff;
    border: 1px solid #AFBCD5;
    box-sizing: border-box;
    border-radius: 4px;
    height: 45px;
    &::placeholder {
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #626262;
    }
    &::-ms-input-placeholder {
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #626262;
    }
    &::-ms-input-placeholder {
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #626262;
    }
  }
  .input_label {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 42px;
    color: #000000;
  }
}
.input_error {
  input {
    border: 1px solid #FF6F3E;
  }
  .error_message {
    color: #FF6F3E;
    position: absolute;
    bottom: -20px;
  }
}