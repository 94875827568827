.custom-select-component {
  &__select {
    [class*="menu"] {
      z-index: 2;
    }
  }
  &__error-msg {
    display: block;
    margin-top: 2px;
    font-size: 10px;
    line-height: 14px;
    color: #FF6F3E;
    font-weight: 400;
  }
  .custom-select-component__select.custom-select-component__select--error {
    [class*="control"] {
      border: 1px solid #FF6F3E;
    }
  }
  .custom-select-component__select.custom-select-component__select--disabled {
    opacity: 0.5;
  }
}