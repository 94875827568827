.small-plus-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 140px;
  &__icon {
    display: block;
    width: 17px;
    height: 17px;
    background: url('../../public/assets/icons/plus-icon-for-shift.svg') center center/cover no-repeat;
    margin-right: 6px;
  }
  &__text {
    display: block;
    color: #5c8de8;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter';
    font-weight: 400;
  }
}
