.custom-checkbox-wrap {
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;
}
.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  min-width: 14px;
  width: 14px;
  height: 14px;
  background: #ffffff;
  border: 1px solid #616882;
  &__icon {
    display: block;
    min-width: 10px;
    width: 10px;
    height: 10px;
  }
  &__title {
    display: block;
    font-size: 14px;
    line-height: 1;
    color: #212121;
    margin-left: 8px;
  }
  &.custom-checkbox--active {
    .custom-checkbox__icon {
      background: url("../../public/assets/icons/checkbox-check-icon.svg") center center/contain no-repeat;
    }
  }
}
