.MuiButtonBase-root.chevron-button {
  background-color: #5C8DE8;
  color: white;
  &:hover{
    background-color: rgb(64, 98, 162);
  }
}

.MuiButtonBase-root.chevron-button.disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}