.icon-component {
  display: inline;
  width: auto;
  height: auto;
  line-height: 0;
  font-size: 0;
}
.icon-component {
  display: flex;
}
