.select_mentorship {
  &_container {
    width: 100%;
    border: 1px solid #afbcd5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    height: 45px;
  }
  &_error {
    border: 1px solid #ff6f3e;
  }
  &_error_message {
    font-family: 'Inter', sans-serif;
    color: #ff6f3e;
    font-size: 10px;
    line-height: 14px;
    padding: 5px;
  }
}
