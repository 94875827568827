.radio_button {
  &_wrapper {

  }
  &_item {
    width: max-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin-bottom: 12px;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    label {
      cursor: pointer;
    }
  }
  &_checkmark {
    width: 14px;
    height: 14px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 100%;
    border: 1px solid #BDBDBD;
    margin-right: 8px;
  }
  &_checked {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: #FFCD00;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      top: 1px;
      left: 1px;
    }
  }
}