body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h4 {
  text-align: center;
  font: normal normal normal 22px/30px Open Sans;
  letter-spacing: 0px;
  color: #3D3D3D;
  opacity: 1;
}

h2 {
  text-align: center;
  font: normal normal normal 22px/24px Open Sans;
  letter-spacing: 0px;
  color: #3D3D3D;
}

p {
  font: normal normal normal 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #3D3D3D;
}

label {
  text-align: right;
  font: normal normal bold 22px/30px Open Sans;
  letter-spacing: 0px;
  color: #3D3D3D;
  opacity: 1;
}

.button {
  border-radius: 5px;
  opacity: 1;
  width: 195px;
  height: 49px;
  margin-top: 25px !important;
  margin-left: 8px !important;
}

.submit {
  background-color: #5C8DE8FA !important;
  color: white !important;
  box-shadow: 0px 3px 6px #00000029;
}

.back {
  background-color: white !important;
  color: #5C8DE8FA !important;
  border: 2px solid #5C8DE8FA !important;
}

.submit:disabled {
  background-color: #777777 !important;
}

.invite-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.invite-header.label-sign-up{
  font-size: 1.5rem;
  padding: 5px;
  font-weight: 700;
}
.invite-header.label-sign-up-instructions{
  font-size: 1rem;
  padding: 5px;
  text-align: center;
}
.invite-header > p,.invite-header > h2 {
  text-align: center;
  max-width: 500px;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  margin: 0 0 10px 0;
}

.checkbox-input {
  width: 13px !important;
}

.form-container {
  margin: 2vh 6vw;
}
/* max-width: 599.95px is same as theme.breakpoints.down(600) */
@media screen and (max-width: 599.95px) {
  .invite-header > p,.invite-header > h2 {
    font-size: 14px;
    margin-bottom: 5px;
    max-width: 320px;
  }

  .invite-header > p {
    line-height: 20px;
    letter-spacing: 0.25px;
  }
}
