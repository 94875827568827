.duplicate-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  &__icon {
    display: block;
    width: 16px;
    height: 19px;
    background: url("../../public/assets/icons/copy-icon.svg") center center/cover no-repeat;
    margin-right: 6px;
  }
  &__text {
    display: block;
    color: #5C8DE8;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter';
    font-weight: 400;
  }
}
