.big-plus-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 115px;
  &__icon {
    display: block;
    width: 20px;
    height: 20px;
    background: url('../../public/assets/icons/plus-icon-for-shift-filled.svg') center center/cover no-repeat;
    margin-right: 10px;
  }
  &__text {
    display: block;
    color: #5c8de8;
    font-size: 18px;
    font-family: 'Inter';
    font-weight: 600;
    line-height: 1;
  }
}
