.geofence-radius {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 18px;
  p {
    margin: 0;
    padding: 0;
    display: block;
    font-size: 16px;
    font-family: Open Sans, sans-serif;
    color: #000;
    font-weight: 600;
    line-height: 42px;
  }

  .geofence-slider {
    width: 60%;
    height: 4px;
    background: #DDE4F0;
    display: flex;
    align-items: center;

    .geo-slide {
      background: #ffffff;
      height: 18px;
      width: 18px;
      display: inline-flex;
      border-radius: 50%;
      border: 1px solid #BDBDBD;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: grab;
      z-index: 10;

      &:after {
        content: '';
        background: #FFCD00;
        display: inline-block;
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
    .hidden-drag-ghost {
      opacity: 0;
    }
  }
}
